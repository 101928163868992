'use strict';

Gri.module({
  name: 'list-alpha',
  ieVersion: null,
  $el: $('.list-alpha'),
  container: '.list-alpha',
  fn: function () {
    
  }
});
